@import url("https://use.typekit.net/atk3mme.css");

body {
  padding: 0 !important;
  font-family: fira-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 62.5%;
  min-width: 100vw;
  min-height: 100vh;
}
html {
  min-width: 100vw;
  min-height: 100vh;
}
* {
  font-family: fira-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.coin-logo {
  width: 1.1em;
  height: 1.1em;
  margin-right: 0.4em;
  margin-left: 0.4em;
  border-radius: 50px;
}

@media (max-width: 1000px) {
  .wrapper {
    position: absolute;
    margin: auto;
  }
}

@media (max-width: 800px) {
  .coin-logo {
    margin-left: 0.8em;
    margin-right: 0.8em;
  }

  body {
    width: 100vw;
    height: 100vh;
    padding: 0.5em;
  }
}

#hydra-ticker {
  margin-right: 0.5rem;
  font-weight: bold;
  font-size: 1em;
  color: #6da8ff;
}

#staking-div {
  font-weight: 500;
  background-image: radial-gradient(
    50% 50% at 50% 50%,
    rgba(33, 114, 229, 0.1) 0%,
    rgba(33, 36, 41, 0) 100%
  );
}

#staked-balance {
  font-size: 0.7em;
  margin-right: auto;
  margin-top: 0.1em;
  margin-bottom: 0.4em;
  text-decoration: underline;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#extension-version-or-mobile-device-banner {
  width: 100vw;
  background-color: palevioletred;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  text-align: center;
}

#connect-extension-banner {
  width: 100vw;
  background-color: palegoldenrod;
  display: flex;
  justify-content: center;
  font-size: 20px;
  text-align: center;
}

.loading-spinner {
  margin: 1.2em;
  width: 2em;
  height: 2em;
  background-color: orange;
  border-radius: 50%;
  animation: loading-animation 1s infinite;
}
.loading-loading-spinner--successful {
  margin: 1.2em;
  width: 2em;
  height: 2em;
  background-color: green;
  border-radius: 50%;
  animation: loading-animation 3s 1;
}

.loading-spinner--error {
  margin: 1.2em;
  width: 2em;
  height: 2em;
  background-color: red;
  border-radius: 50%;
  animation: loading-animation 3s 1;
}

@keyframes loading-animation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.orange {
  margin: 1.2em;
  width: 1em;
  height: 1em;
  background-color: orange;
  border-radius: 50%;
}
#close-notification-button {
  margin: 0.25em 0.25em auto auto;

  border-radius: 7px;
  border: none;
  font-weight: 500;
  background-color: antiquewhite;
}

.notification-message-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  margin-left: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.warning-message-box {
  margin: 1em;
}
